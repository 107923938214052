//统一管理api
import { base_roar } from "./base"; // 导入接口域名列表
import { get, _post } from "./tool"; // 导入http中创建的axios实例
//twitter登录
export const login = (params) => get(base_roar + "/login/twitter", params);
//获取用户信息
export const getConfig = (params) => _post(base_roar + "/login/safe_token", params);
//设置邀请码
export const setInviteCode = (params) => _post(base_roar + "/invite/set_invite", params);
//邀请主页
export const invitePage = (params) => _post(base_roar + "/invite/info", params);
//获取任务列表
export const taskInfo = (params) => _post(base_roar + "/task/info", params);
//领取任务奖励
export const taskClaim = (params) => _post(base_roar + "/task/claim", params);
//兑换币种
export const exchangeSymbol = (params) => _post(base_roar + "/exchage/symbol", params);
//兑换历史记录
export const exchangeHistory = (params) => _post(base_roar + "/exchage/history_record", params);

export default { login, getConfig, setInviteCode, invitePage, taskInfo, taskClaim, exchangeSymbol, exchangeHistory };
