const Home = () => import("@/views/Home");
const LoadingPage = () => import("@/views/LoadingPage");
const Logging = () => import("@/views/Logging");
const Airdrop = () => import("@/views/Airdrop");
const EnterCode = () => import("@/views/EnterCode");
const TaskCenter = () => import("@/views/TaskCenter");
const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/home",
        name: "home",
        component: Home,
    },
    {
        path: "/loadingPage",
        name: "loadingPage",
        component: LoadingPage,
    },
    {
        path: "/logging",
        name: "logging",
        component: Logging,
    },
    {
        path: "/airdrop",
        name: "airdrop",
        component: Airdrop,
    },
    {
        path: "/enterCode",
        name: "enterCode",
        component: EnterCode,
    },
    {
        path: "/taskCenter",
        name: "taskCenter",
        component: TaskCenter,
    },
];
export default routes;
